<template>
	<!-- 下载弹窗内容 -->
	<div class="downloadBox" style="padding: 0;font-size: 0;">
		<!-- <div class="title">{{ $t('home.downloadUrl') }}</div> -->
		<img class="dImg" :src="downloadTips">
		<div class="btnImg playerBtn">
			<a :href="activeItem.ldUrl" download><img class="dImg" :src="playerImg"></a>
		</div>
		<div class="btnImg gamerarBtn">
			<a :href="activeItem.pcUrl" download><img class="dImg" :src="gamerarImg"></a>
		</div>
	</div>
</template>

<script setup>
	import { getCurrentInstance,onMounted,ref,computed,defineProps } from 'vue'
	import useConfigStore from '@/store/modules/config'
	const { proxy } = getCurrentInstance();
	// Import the functions you need from the SDKs you need
	
	const lang = proxy.$i18n.locale
	const playerImg = require('@/assets/images/'+lang+'/player_btn.png')
	const gamerarImg = require('@/assets/images/'+lang+'/gamerar_btn.png')
	const downloadTips = require('@/assets/images/'+lang+'/pcsetup_tips.png')
	const props = defineProps({
		gameInfo: Object
	})
	const activeItem = ref({})
	const isMobile = computed(() => {
		return useConfigStore().isMobile
	})
	onMounted(() => {
		activeItem.value = Object.assign({},props.gameInfo)
	})
	
</script>

<style lang="less" scoped>
	.dImg{
		width: 100%;
	}
	.btnImg{
		position: absolute;left: 50%;transform: translate(-50%,0);
		width: 70%;cursor: pointer;
		a{
			display: inline-block;
		}
	}
	.playerBtn{
		top: 14%;
	}
	.gamerarBtn{
		top: 28%;
	}
</style>